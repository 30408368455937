export default {
    "invest": {
        "investProjectTitle": "Инвестиционный проект",
        "download": "Скачать",
        "downloadExport": "Скачать выгрузку",
        "roadmap": "Дорожная карта",
        "addProject": "Добавить проект",
        "noProjects": "Нет проектов",
        "fileLoading": "Генерация файла",
        "fileCreatedSuccess": "Файл успешно создан",
        "fileCreationError": "Ошибка при генерации файла",
        "downloadFile": "Выгрузка",
        "downloadRoadmap": "Дорожная карта",
        "noHistory": "Нет истории",
        "changeAuthor": "Автор изменений:",
        "projectInfo": "Информация о проекте",
        "documents": "Документы",
        "changeHistory": "История изменений",
        "projectTasks": "Задачи проекта",
        "createProject": "Создать проект",
        "createProjectConfirmation": "Создать проект с задачами для инвестиционного проекта?",
        "create": "Создать",
        "cancel": "Отмена",
        "projectCreated": "Проект создан",
        "projectCreationError": "Ошибка создания проекта",
        "organization": "Организация",
        "projectAuthor": "Автор проекта",
        "plannedDeadline": "Планируемый срок ввода",
        "open": "Открыть",
        "startDate": "Дата начала",
        "category": "Категория",
        "cadasterNumber": "Кадастровый номер ЗУ",
        "phone": "Телефон",
        "location": "Местоположение",
        "status": "Статус",
        "stage": "Этап",
        "psdDeveloped": "ПСД разработана",
        "psdNotDeveloped": "ПСД не разработана",
        "status": "Статус",
        "projectPeriod": "Сроки проекта",
        "projectRegion": "Регион проекта",
        "projectCost": "Стоимость проекта",
        "mlnTenge": "млн. тенге",
        "mlnTenge2": "млн.тг",
        "amount": "Количество",
        "permanent": "Постоянные",
        "temporary": "Временные",
        "postoyannye": "Постоянные",
        "vremennye": "Временные",
        "invest_mesto": "место",
        "invest_mest": "мест",
        "invest_mest_plural": "мест",
        "invest_sobrano_sredstv": "собрано средств",
        "main_info": "Основная информация о проекте",
        "main_info2": "Основная информация",
        "director": "Директор",
        "foreign_investor": "Иностранный инвестор:",
        "start_date": "Дата начала проекта:",
        "planned_completion": "Планируемый срок ввода в эксплуатацию (месяц, год):",
        "not_specified": "Не указана",
        "no": "Нет",
        "yes": "Да",
        "psd_developed": "ПСД разработана",
        "psd_not_developed": "ПСД не разработана",
        "company_name": "Наименование предприятия",
        "company_bin": "БИН предприятия",
        "subcategory": "Подкатегория",
        "director_phone": "Телефон директора",
        "foreign_investor": "Иностранный инвестор",
        "no": "Нет",
        "project_capacity": "Мощность проекта",
        "start_date": "Дата начала проекта",
        "project_cost": "Стоимость проекта",
        "implementation_stage": "Этап реализации",
        "construction_stage": "Стадия СМР",
        "infrastructure": "Проведенные инфраструктуры",
        "funding_sources": "Источники финансирования",
        "comment": "Комментарий",
        "additional_info": "Дополнительная информация",
        "temporary_jobs": "Временные рабочие места",
        "permanent_jobs": "Постоянные рабочие места",
        "land_allocated": "Земельный участок выделен",
        "yes": "Да",
        "land_plot": "Земельный участок",
        "cadaster": "Кадастровый номер ЗУ",
        "total": "Всего",
        "invest_ne_udalos_poluchit_statistiku": "Не удалось получить данные статистики",
        "filterTitle": "Фильтровать по статусу",
        "invest_proekt_s": "проект",
        "invest_proekta_s": "проекта",
        "invest_proektov_s": "проектов",
        "data_update": {
            "title": "Актуализация данных"
        },
        "status": "Статус",
        "project_stage": "Стадия проекта",
        "installation_stage": "Стадия СМР, %",
        "project_capacity_placeholder": "Введите проектную мощность",
        "measure_unit": "Единица измерения",
        "funding_source": "Источник финансирования",
        "funding_source_placeholder": "Выберите источник финансирования",
        "funding_volume": "Объем финансирования",
        "funding_volume2": "Объем финансирования, млн. тенге",
        "funding_volume_placeholder": "Введите объем финансирования",
        "comment": "Комментарий",
        "comment_placeholder": "Введите комментарий",
        "temporary": "Временные",
        "temporary_jobs_placeholder": "Введите количество временных рабочих мест",
        "permanent": "Постоянные",
        "permanent_jobs_placeholder": "Введите количество постоянных рабочих мест",
        "total_project_cost": "Общая стоимость проекта",
        "total_project_cost2": "Вся стоимость проекта, млн. тенге",
        "add_funding_source": "Добавить источник финансирования",
        "remove": "Удалить",
        "save_changes": "Сохранить изменения",
        "edit": "Редактировать",
        "delete": "Удалить",
        "source": "Источник финансирования",
        "workplaces": "Рабочие места",
        "delete_project": {
            "confirm_title": "Удалить проект \"{project_name}\"?",
            "confirm_okText": "Удалить",
            "confirm_cancelText": "Отмена",
            "success_message": "Проект \"{project_name}\" удален.",
            "error_message": "Ошибка удаления проекта"
        },
        "change_status": {
            "success_message": "Статус успешно обновлен",
            "error_message": "Ошибка изменения статуса"
        },
        "form_submit": {
            "success_message": "Проект успешно обновлен",
            "error_message": "Ошибка сохранения",
            "success_created": "Проект успешно создан"
        },
        "form": {
            "project_info": "Основная информация о проекте",
            "step": "Шаг {step}",
            "project_name": "Наименование проекта",
            "enter_project_name": "Напишите наименование проекта",
            "organization": "Организация",
            "location": "Местоположение",
            "not_specified": "Не указана",
            "region": "Область/Город республиканского значения",
            "select_project_region": "Выберите регион проекта",
            "district": "Район/Город областного значения",
            "select_district": "Выберите район/город областного значения",
            "akimat": "Акимат",
            "select_akimat": "Выберите акимат",
            "settlement": "Поселение",
            "select_settlement": "Выберите поселение",
            "village": "Село",
            "select_village": "Выберите село",
            "company_bin": "БИН предприятия",
            "enter_company_bin": "БИН предприятия",
            "company_name": "Наименование предприятия",
            "enter_company_name": "Наименование предприятия",
            "category": "Категория",
            "select_category": "Укажите направление",
            "subcategory": "Подкатегория",
            "select_subcategory": "Укажите отрасль",
            "comment": "Комментарий",
            "add_comment": "Добавьте комментарий",
            "company_director_name": "ФИО руководителя предприятия",
            "contact_phone_number": "Контактный номер телефона",
            "project_stage": "Стадия проекта",
            "specify_project_stage": "Укажите стадию проекта",
            "foreign_investor": "Иностранный инвестор",
            "specify_foreign_investor": "Укажите иностранного инвестора (при наличии)",
            "project_capacity": "Мощность проекта",
            "specify_project_capacity": "Укажите мощность проекта",
            "measure_unit": "Единица измерения",
            "project_start_date": "Дата начала проекта",
            "planned_completion_date": "Планируемый срок ввода в эксплуатацию (месяц, год)",
            "planned_completion_date_p": "Планируемый срок ввода в эксплуатацию",
            "project_cost": "Стоимость проекта",
            "total_project_cost": "Вся стоимость проекта, млн. тенге",
            "total_project_cost_p": "Вся стоимость проекта",
            "funding_source": "Источник финансирования",
            "select_funding_source": "Укажите источник финансирования",
            "funding_volume": "Объем финансирования, млн. тенге",
            "enter_funding_volume": "Укажите объем финансирования",
            "add_comment_2": "Добавьте короткий комментарий",
            "remove": "Удалить",
            "add_funding_source": "Добавить источник финансирования",
            "implementation_stage": "Этап реализации",
            "psd": "ПСД",
            "developed": "Разработана",
            "not_developed": "Не разработана",
            "construction_stage": "Стадия СМР, %",
            "construction_stage_placeholder": "Стадия СМР, %",
            "infrastructure_info": "Проведенные инфраструктуры",
            "describe_infrastructure": "Опишите какая инфраструктура проведена",
            "additional_info": "Дополнительная информация",
            "temporary_jobs": "Временные рабочие места",
            "enter_temporary_jobs": "Укажите количество временных рабочих мест",
            "permanent_jobs": "Постоянные рабочие места",
            "enter_permanent_jobs": "Укажите количество постоянных рабочих мест",
            "land_plot_allocated": "Земельный участок выделен",
            "land_plot_area": "Земельный участок (количество гектар)",
            "enter_land_plot_area": "Укажите земельный участок",
            "cadaster_number": "Кадастровый номер ЗУ",
            "enter_cadaster_number": "Кадастровый номер ЗУ",
            "project_documents": "Документы проекта",
            "attach_documents": "Прикрепить документы",
            "create_project": "Создать проект",
            "save_invest_project": "Сохранить инвест.проект",
            "create_invest_project": "Создать инвест.проект",
            "cancel": "Отмена",
            "add_invest_p": "Добавить инвестпроект",
            "edit_invest_p": "Редактировать инвестпроект"
        }
    }
}